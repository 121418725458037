import CustomLink from '@components/shared/CustomLink';
import Icons from '@components/shared/Icons';
import Loader from '@components/shared/Loader';
import type { NextPage } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import styled from 'styled-components';

const StyledHome = styled.div``;

const Home: NextPage = () => {
  const router = useRouter();
  useEffect(() => {
    router.push('/login');
  }, [router]);
  return (
    <StyledHome>
      <Loader />
    </StyledHome>
  );
};

export default Home;
